import Component from '../core/Component';
import { queryAll } from '../utils/dom'
import $ from 'jquery'

export default class Form extends Component {

    prepare() {
        const inputs = queryAll('.Input, .Textarea', this.element);
        inputs.forEach(input => {
            this.setClass({target: input})
            input.addEventListener("change", :: this.setClass);
        });

        const hash = document.getElementById("prihlaska") || document.getElementById("snippet--contactForm")

        if (hash) {
            if ($.nette) {
                $.nette.ext('scroll', {
                    success: function() {
                        hash.scrollIntoView()
                    }   
                });
            }
        }

        this.element.addEventListener('nette:form:valid',::this.loadingBar)
    }

    setClass(event) {
        let input = event.target,
            input_value = input.value;

        if (input_value.trim() != '') {
            input.classList.add("is-filled");
        } else {
            input.classList.remove("is-filled");
        }
    }

    loadingBar() {
        this.element.querySelector('button').classList.add("is-loading")
    }


}
