import Component from '../core/Component';

export default class FixedBlock extends Component {
    constructor(element) {
        super(element)
        this.ref = {
            close: null
        }
    }

    prepare() {
        this.ref.close.addEventListener("click",::this.handleClick)
        this.element.addEventListener("click",:: this.show)
    }

    handleClick(e) {
        e.preventDefault();
        
        this.element.classList.add("is-hidden")
    }

    show() {
        if (window.matchMedia("(max-width: 768px)").matches) {
            this.element.classList.add("is-active")
        }
    }

}