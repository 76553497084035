import Component from '../core/Component';
import $ from 'jquery'
import {CMS} from '../core/config'


export default class CountNumbers extends Component {
	constructor(element) {
		super(element)

		this.ref = {
			numbers: []
		}
	}

	prepare() {
		if (CMS) {
			return;
		}

		window.addEventListener("scroll",::this.startAnimation)
		this.startAnimation()
	}

	startAnimation() {
		let oTop = this.element.getBoundingClientRect().top + window.pageYOffset - window.innerHeight


		if (!this.done && window.pageYOffset > oTop) {
			$('.Count-number').each(function() {
			var $this = $(this),
				countTo = $this.attr('data-count');



				$({countNum: $this.text()}).animate({countNum: countTo},{
					duration: 1000,
					easing: 'linear',
					step: function() {
						$this.text(Math.floor(this.countNum));
					},
					complete: function() {
						$this.text(this.countNum);
					}
				});
			});

			this.done = true
		}
	}
}