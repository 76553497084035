import scrollTo from './scrollTo';

function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
}

export default function scrollToElement(element, offset) {
    const box = element.getBoundingClientRect();
    const scrollTop = getScrollTop();

    const target = scrollTop + box.top + offset;

    scrollTo(target);
}