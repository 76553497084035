import Component from '../core/Component'
export default class File extends Component
{
    constructor(element) {
        super(element)
        this.ref = {
            label: null,
            input: null
        }
    }

    prepare() {        
        this.ref.input.addEventListener("change",::this.handleChange)
    }

    handleChange() {
        const fileName = this.ref.input.value.replace(/.*[\/\\]/, '')

        if (fileName != '') {
            this.ref.label.innerHTML = `<strong>${fileName}</strong>`
        }
    }
}