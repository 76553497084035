import Component from '../core/Component'

const STATES = {
    ACTIVE: 'is-active',
}

export default class Nav extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            content: null,
        }
    }

    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeydown)
    }

    handleClick = (event) => {
        if (!this.ref.content.contains(event.target)) {
            this.close()
        }
    }

    handleKeydown = (event) => {
        if (event.key === 'Escape') {
            this.close()
        }
    }

    open() {
        if (this.is(STATES.ACTIVE)) {
            return
        }

        this.element.classList.add(STATES.ACTIVE)
        document.addEventListener('keydown', this.handleKeydown)
        this.emit('open')
    }

    close() {
        if (!this.is(STATES.ACTIVE)) {
            return
        }

        this.element.classList.remove(STATES.ACTIVE)
        document.removeEventListener('keydown', this.handleKeydown)
        this.emit('close')
    }

    toggle() {
        if (this.is(STATES.ACTIVE)) {
            this.close()
        } else {
            this.open()
        }
    }
}
