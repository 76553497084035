import Component from '../core/Component';

export default class TeachersMoving extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            items: []
         }
    }
    prepare() {
        const mq = window.matchMedia("(min-width: 768px)")
        if (mq.matches) {
            window.addEventListener('scroll', this.handleScroll, {passive: true})
        }
    }

    destroy() {
        if (mq.matches) {
            window.removeEventListener('scroll', this.handleScroll, { passive: true })
        }
    }

    handleScroll = (event) => {
        let elHeight = parseInt(getComputedStyle(this.element).height);

        let pageScrolled = window.pageYOffset
        let containerOffset = this.element.getBoundingClientRect().top + pageScrolled - 35

        let scrolledFromEl = (containerOffset - pageScrolled) * (-1)

        let percentageValue = Math.round(100 * (scrolledFromEl) / (elHeight))

        let steps = percentageValue * 0.2

        this.ref.items.forEach((item,i)=>{
            if (percentageValue > 0 && percentageValue < 100) {
                if (i % 3 === 0) {
                    item.style.transform = 'translateY(-'+steps+'%)'
                }

                if (i % 3 === 2) {
                    item.style.transform = 'translateY('+steps+'%)'
                }
            }
        })
    }
}