import Component from '../core/Component';


export default class Faq extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            header: '',
            contents: [],
            items: []
        }
    }

    prepare() {
        this.ref.items.forEach((item,index) => item.addEventListener('click', event => {
            this.handleClick(event,index)
        }))
    }

    handleClick(event,index) {
        let cur_item = event.currentTarget
        const isMobile = window.matchMedia("(min-width: 768px)")
        
        if (!cur_item.classList.contains('is-active')) {
            this.hideAll(cur_item)
            this.show(cur_item, index)
        } else {  
            this.hide(cur_item, index)
        }
    }

    show(cur_item,index) {
        cur_item.classList.add('is-active')

        this.ref.contents[index].style.height = 'auto'

        var height = this.ref.contents[index].offsetHeight + 'px';

        this.ref.contents[index].style.height = '0px';

        setTimeout(()=> {
            this.ref.contents[index].style.height = height;
        }, 0); 
    }

    hide(cur_item, index) {
        this.ref.contents[index].style.height = 0;

        this.ref.contents[index].addEventListener('transitionend', ()=> {
            cur_item.classList.remove('is-active');
        }, {
          once: true
        });
    }

    hideAll(cur_item) {
        this.ref.contents.forEach((content) => {
            content.style.height = 0;
        })

        this.ref.items.forEach((item) => {
            item.classList.remove('is-active');
        })
    }
}