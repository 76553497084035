import Component from '../core/Component'
import scrollToElement from '../services/Viewport/scrollToElement'

const STATES = {
    ACTIVE: 'is-active',
}

export default class ActiveMenu extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            links: [],
        }

        this.state = {
            selectedId: '',
        }

        this.sections = []
    }

    prepare() {
        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleScroll)
        this.ref.links.forEach((link) => link.addEventListener('click', this.handleItemClick))
        document.documentElement.addEventListener('wheel', this.handleWheel)

        this.resize()
        this.render()
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('scroll', this.handleScroll)
        document.documentElement.removeEventListener('wheel', this.handleWheel)
    }

    handleWheel = () => {
        if (this.state.selectedId) {
            this.state.selectedId = ''
        }
    }

    handleResize = () => {
        this.resize()
    }

    handleScroll = () => {
        this.render()
    }

    handleItemClick = (event) => {
        event.preventDefault()
        let id = event.currentTarget.getAttribute('href').split('#')[1]

        const element = document.getElementById(id)

        if (!element) {
            return
        }

        this.state.selectedId = id

        scrollToElement(element, 80)
    }

    resize() {
        const elements = [...document.querySelectorAll('.u-scrollSection')]
        this.sections = elements.map((element) => {
            const box = element.getBoundingClientRect()
            return {
                top: box.top + window.pageYOffset - 48,
                bottom: box.top + window.pageYOffset - 48 + box.height,
                id: element.getAttribute('id'),
            }
        })

        this.render()
    }

    render() {
        let currentPosition = window.pageYOffset

        const currentSectionId = this.sections.find(
            (section) => currentPosition >= section.top && currentPosition <= section.bottom,
        )?.id

        const selectedId = this.state.selectedId || currentSectionId

        if (!selectedId) {
            return
        }

        this.ref.links.forEach((link) =>
            link.classList.toggle(
                STATES.ACTIVE,
                link.getAttribute('href').split('#')[1] === selectedId,
            ),
        )

        if (this.state.selectedId === currentSectionId) {
            this.state.selectedId = ''
        }
    }
}
