import Component from '../core/Component';


export default class Filter extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            trigger:null,
            items: []
        }
    }

    prepare() {
        this.ref.trigger.addEventListener('change',::this.handleChange)
    }

    handleChange(e) {
        const selectedCategory = e.target.children[0].value

        this.ref.items.forEach(item => {
            let categories = item.dataset.categories

            if (categories.indexOf(selectedCategory) > -1) {
                item.classList.remove("is-hidden")
            } else {
                item.classList.add("is-hidden")
            }
        });
    }
}