// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'

// project
import ActiveMenu from './ActiveMenu'
import Carousel from './Carousel'
import CarouselTeachers from './CarouselTeachers'
import FixedBlock from './FixedBlock'
import CustomScrollbar from './CustomScrollbar'
import CountNumbers from './CountNumbers'
import Faq from './Faq'
import File from './File'
import Filter from './Filter'
import Form from './Form'
import Nav from './Nav'
import NavOpener from './NavOpener'
import Modal from './Modal'
import ModalOpen from './ModalOpen'
import Select from './Select'
import SmoothScroll from './SmoothScroll'
import Sticky from './Sticky'
import TeachersMoving from './TeachersMoving'
import CMSPageEdit from './CMSPageEdit'

const Components = {
    Antispam,
    // CookieBubble,
    ActiveMenu,
    Carousel,
    CarouselTeachers,
    FixedBlock,
    CMSPageEdit,
    CustomScrollbar,
    CountNumbers,
    Faq,
    File,
    Filter,
    Form,
    Nav,
    NavOpener,
    Modal,
    ModalOpen,
    Select,
    SmoothScroll,
    Sticky,
    TeachersMoving,
}

export default Components
