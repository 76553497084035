import scrollToElement from '../../services/Viewport/scrollToElement';
import $ from 'jquery';

document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = element.parentNode.querySelector('.InputError')
        if (errorElement) {
            if (element.classList.contains('validateParent')) {
                element.parentNode.parentNode.removeChild(errorElement);
            } else {
                element.parentNode.removeChild(errorElement);
            }
        }
    }

    element.classList.remove('has-error');
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    let errorElement = element.parentNode.querySelector('.InputError')

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    if (element.classList.contains('validateParent')) {
        element.parentNode.parentNode.appendChild(errorElement);
    } else {
        element.parentNode.appendChild(errorElement);
    }
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target));
document.addEventListener('nette:form:invalid', event => {
    const $form = $(event.target);
    const $error = $form.find('.InputError');
    let $target = $error.closest('.FormItem');
    scrollToElement($target.length ? $target[0] : $form[0], -120);
});