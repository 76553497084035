import Component from '../core/Component';
import Flickity from 'flickity'


export default class CarouselTeachers extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            content: '',
            next: '',
            prev: ''
        }
    }

    prepare() {
        this.carouselOptions()
    }


    carouselOptions() {
        if ( matchMedia('screen and (max-width: 767px)').matches ) {
          this.flickity = new Flickity(this.element, {
              draggable: true,
              pageDots: false,
              prevNextButtons: false,
              cellAlign: 'left',
              contain: false
          });
        } 
    }
}