import Component from '../core/Component';
import { enableScrolling, disableScrolling } from "../services/Viewport/scroll";

const STATES = {
    ACTIVE: 'is-active',
}

export default class Modal extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            content: null,
            close: ''
         }
    }

    prepare() {
        this.element.addEventListener('click', this.handleClick)
        this.ref.close.addEventListener('click', this.handleCloseClick)
        document.addEventListener('keydown', this.handleKeydown)
    }

    handleClick = (event) => {
        if (!this.ref.content.contains(event.target)) {
            this.close()
        }
    }
    
    handleCloseClick = (event) => {
        if (event) {
            event.preventDefault();
        }
        
        enableScrolling();
        this.element.classList.remove(STATES.ACTIVE)
        document.removeEventListener('keydown', this.handleKeydown)
    }

    handleKeydown  = (event) =>  {
        if (event.keyCode === 27) {
            this.close()
        }
    }

    open() {
        disableScrolling();
        this.element.classList.add(STATES.ACTIVE)
        document.addEventListener('keydown', this.handleKeydown)
    }

    close(event) {
        if (event) {
            event.preventDefault()
        }

        enableScrolling();
        this.element.classList.remove(STATES.ACTIVE)
        document.removeEventListener('keydown', this.handleKeydown)
    }
}