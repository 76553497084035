import Component from '../core/Component'
import { queryAll } from '../utils/dom'

export const STATES = {
    LOADING: 'is-loading',
    SAVED: 'is-saved',
}

export default class CMSPageEdit extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            submit: null,
        }
    }

    prepare() {
        document.addEventListener('click', (event) => {
            if (
                event.target.closest('a:not(.is-system-link)') ||
                event.target.closest('button:not(.is-system-button)')
            ) {
                event.preventDefault()
            }
        })

        document.addEventListener('focusin', this.handleFocus)

        this.ref.submit.addEventListener('click', this.handleClick)
        this.initEditor()
    }

    initEditor() {
        Array.from(document.querySelectorAll('[data-key]'))
            .filter((element) => {
                const tag = element.parentNode.tagName.toLowerCase()
                element.addEventListener('click', () => {
                    this.ref.submit.classList.remove(STATES.LOADING)
                    this.ref.submit.classList.remove(STATES.SAVED)
                })
                return tag == 'div' || tag == 'aside' || tag == 'section'
            })
            .forEach((element) => {
                element.dataset.ckeditor = 'true'
                CKEDITOR.inline(element, {
                    toolbar: element.dataset.toolbar || 'Inline',
                })
            })

        Array.from(document.querySelectorAll('[data-key]'))
            .filter((element) => !element.dataset.ckeditor)
            .forEach((element) => {
                element.addEventListener('paste', (event) => {
                    event.preventDefault()
                    const paste = (event.clipboardData || window.clipboardData).getData('text')

                    const selection = window.getSelection()
                    if (!selection.rangeCount) {
                        return
                    }

                    selection.deleteFromDocument()
                    selection.getRangeAt(0).insertNode(document.createTextNode(paste))
                    selection.collapseToEnd()
                })
                console.log(element)
            })
    }

    handleFocus = (event) => {
        const target = event.target.closest('[contenteditable="true"]')

        if (!target) {
            return
        }

        const key = target.dataset.key

        const elements = queryAll(`[data-key="${target.dataset.key}"]`)

        if (elements.length < 2) {
            return
        }

        this.attachGroupEditing(
            target,
            elements.filter((element) => {
                if (element !== target) {
                    return Component.getFromElement(element)
                }
            }),
        )
    }

    handleClick = (event) => {
        event.preventDefault()

        const fields = Array.from(document.querySelectorAll('[data-key]')).reduce(
            (acc, element) => {
                let value = ''

                if (!element.dataset.ckeditor && element.querySelector('a')) {
                    value = element.innerHTML.replace(
                        element.querySelector('a').outerHTML,
                        element.querySelector('a').innerHTML,
                    )
                } else {
                    value = element.innerHTML
                }

                acc[element.dataset.key] = value
                return acc
            },
            {},
        )

        this.ref.submit.classList.add(STATES.LOADING)
        this.ref.submit.classList.remove(STATES.SAVED)

        this.send(fields)
    }

    attachGroupEditing(master, slaves) {
        const edit = (event) => {
            slaves.forEach((slave) => (slave.innerHTML = master.innerHTML))
        }

        const blur = (event) => {
            master.removeEventListener('input', edit)
            master.removeEventListener('blur', blur)
        }

        master.addEventListener('input', edit)
        master.addEventListener('blur', blur)
    }

    send(fields) {
        var xhr = new XMLHttpRequest()
        var submit = this.ref.submit
        xhr.open('POST', this.element.dataset.url, true)
        xhr.setRequestHeader('Content-Type', 'application/json')

        xhr.onreadystatechange = function () {
            // Call a function when the state changes.
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                submit.classList.remove(STATES.LOADING)
                submit.classList.add(STATES.SAVED)
            }
        }

        xhr.send(JSON.stringify({ fields }))
    }
}
