import Component from '../core/Component';
import scrollToElement from "../services/Viewport/scrollToElement";


export default class SmoothScroll extends Component {
    constructor(element) {
        super(element)

        this.ref = {
        }
    }

    prepare() {
        this.element.addEventListener("click",::this.handleClick)
    }

    handleClick() {
        const id = this.element.getAttribute('href'),
            el = document.querySelector(id)

        scrollToElement(el, -20)
    }
}