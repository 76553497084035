import Component from '../core/Component'

const STATES = {
    ACTIVE: 'is-active',
}

export default class NavOpener extends Component {
    constructor(element) {
        super(element)
        this.ref = {
            button: null
        }
    }

    prepare() {
        this.ref.button.addEventListener('click', this.handleClick)
    }

    handleClick = (event) => {
        event.preventDefault()

        const nav = Component.getFromElement('nav')
        nav.on('open', this.handleOpen)
        nav.on('close', this.handleClose)

        nav.toggle()
    }

    handleOpen = () => {
        this.element.classList.add(STATES.ACTIVE)
    }

    handleClose = () => {
        this.element.classList.remove(STATES.ACTIVE)
    }
}
